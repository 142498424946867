.recipeImage {
  width: 100%;
  height: auto;
  max-width: 600px;
  max-height: 400px;
}

.categories {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}
