.recipes {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.searchInput {
  margin-right: 15px;
  max-width: 192px;
  height: 56px;
  margin-bottom: 10px;
}

.categoriesSelect {
  max-width: 200px;
  margin-bottom: 10px !important;
  margin-right: 15px !important;
}

.cookTimeSelect {
  max-width: 200px;
  margin-bottom: 10px !important;
}

@media screen and (max-width: 450px) {
  .categoriesSelect {
    max-width: 100%;
    margin-right: 0 !important;
  }
  .cookTimeSelect {
    max-width: 100%;
  }
  .searchInput {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }
}
